import React, { Suspense, lazy } from 'react'
import { isMobile } from 'react-device-detect';
const HeaderDesktop = lazy(() => import('./desktop/header'))
const HeaderMobile = lazy(() => import('./mobile/header'))
function Header() {
    return <>
        <Suspense fallback={<></>}>
            {!isMobile && <HeaderDesktop />}
            {isMobile && <HeaderMobile />}
        </Suspense>
    </>
}
export default Header