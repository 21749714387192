import React, { useRef, useEffect, lazy, Suspense, useState } from 'react'
import { useParams, Redirect, useLocation } from 'react-router-dom';
import ReviewHeader from './header/header';
import dataTable from '../home/datatable.json'
import { isMobile } from 'react-device-detect';
import { RedirectQueryKey, SiteProps } from '../../constants';
import { GetDocumentTitle, GetMetaDescription } from './content/constants';
import { SetMetaDescription, SetDocumentTitle } from '../../../common/common';

const DesktopReview = lazy(() => import('./desktop/review'))
const MobileReview = lazy(() => import('./mobile/review'))
const GoToSite = lazy(() => import('./gotosite/gotosite'))

const fixedClass = 'fixed'
const atTheBottomClass = 'at-the-bottom'
const nearTheBottomOffset = 90
const topSitesAmount = 3

type SiteDataProps = {
    site: string;
    sitelink: string;
    bnrSitelink: string;
    offertext: string;
    score: number;
}

function Review() {
    const sectionRef = useRef<HTMLDivElement>(null)
    const [rank, setRank] = useState(-1)
    const [topSites, setTopSites] = useState(new Array<SiteProps>())
    const { site } = useParams();
    const [siteData, setSiteData] = useState({ site: '', sitelink: '', offertext: '', score: -1, bnrSitelink: '' })
    useEffect(() => {
        const _data = dataTable.filter(data => data.site.toLocaleLowerCase() === site.toLocaleLowerCase())[0] as SiteDataProps
        setSiteData(_data)
    }, [site])

    useEffect(() => {
        sectionRef.current?.scrollIntoView({ behavior: 'smooth' })

        const sorted = dataTable.sort((d1, d2) => { return d1.score > d2.score ? -1 : 1 }) as SiteDataProps[]
        setRank(sorted.indexOf(siteData) + 1)
        setMeta()

        function setMeta() {
            if (siteData && siteData.site) {
                SetDocumentTitle(GetDocumentTitle(siteData.site))
                SetMetaDescription(GetMetaDescription(siteData.site))
            }
        }
    }, [siteData])

    useEffect(() => {
        const _topSites = dataTable.sort((d1, d2) => { return d1.score > d2.score ? -1 : 1 }).slice(0, topSitesAmount)
        setTopSites(_topSites)

        window.addEventListener('scroll', handleScroll)
        function handleScroll(): void {
            if (sectionRef.current) {
                const sectionPosition = sectionRef.current.getBoundingClientRect().top + window.scrollY
                if (window.scrollY > sectionPosition) {
                    sectionRef.current.classList.add(fixedClass)
                } else {
                    sectionRef.current.classList.remove(fixedClass)
                }

                if (isMobile) {
                    if ((window.innerHeight + window.scrollY + nearTheBottomOffset) >= document.body.offsetHeight) {
                        sectionRef.current.classList.add(atTheBottomClass)
                    } else {
                        sectionRef.current.classList.remove(atTheBottomClass)
                    }
                }
            }
        }
    }, [])

    const query = useQuery()
    const externalId = Number(query.get(RedirectQueryKey));
    const [goToExternal, setGoToExternal] = useState(false)
    useEffect(() => {
        if (query) {
            setGoToExternal(externalId > 0)
        }
    }, [query])

    if (!siteData) {
        return <Redirect to='/' />
    }

    function useQuery() {
        return new URLSearchParams(useLocation().search);
    }

    return <section ref={sectionRef} className={`site-review position-relative d-table w-100 center-h ${!isMobile ? 'margin-top-50' : ''}`}>
        <ReviewHeader site={siteData.site} rank={rank} sitelink={siteData.sitelink} offertext={siteData.offertext} score={siteData.score} />
        <Suspense fallback={<div className='block-screen bg-semi-black'><div className='loader'></div></div>}>
            {!isMobile && <DesktopReview site={site} topSites={topSites} />}
            {isMobile && <MobileReview site={site} topSites={topSites} />}
        </Suspense>

        {
            goToExternal && <Suspense fallback={<>Loading...</>}>
                <GoToSite site={site} sitelink={externalId == 2 ? siteData.bnrSitelink : siteData.sitelink} />
            </Suspense>
        }
    </section>
}

export default Review