import React, { useEffect } from 'react'
import { ContactEmail } from '../../constants';
import { SetDocumentTitle, SetMetaDescription } from '../../../common/common';

const title = 'Our User\'s Privacy is Our Concern'
function Privacy() {
    useEffect(() => {
        SetDocumentTitle(title, true)
        SetMetaDescription('Privacy Policy at LiveCamOffers.com.  Within this page you can find out about our Privacy Policy, what information we collect and how this information is used.')
        window.scroll(
            {
                top: 0,
                behavior: 'smooth'
            }
        )
    }, [])

    return <section className='page center-h d-table margin-top-40 w-100 F14'>
        <br /><h1 className='F30'><strong>{title}</strong></h1>
        <br />Privacy Policy at LiveCamOffers.com, your privacy is considered extremely important to us. Within this page you can find out about our Privacy Policy, what information we collect and how this information is used. We also strongly recommend that you take the time to carefully read the Terms and Conditions as is forms a part of the legal documentation of the Site.
        <br /><br /><h3 className='F16'><strong>The Collected Information</strong></h3>
        <br />This site collects the HTTP referrer information and IP address of all visitors to the site. Cookies may also be used and stored. At no point is it required and at no point will this site ask for its visitors to submit their email address. In the future, it may be possible for visitors to the site to be asked to volunteer their email information. If any information is submitted within the future, these addresses will not be sold, rented or given to any external third parties and will be solely used by LiveCamOffers.com.
        <br /><br /><h3 className='F16'><strong>The Use of Collected Information</strong></h3>
        <br />Any information collected is used as part of our Internet Analytics Software and for the functioning and correct display of the site. This information will help to enhance the users experience and enjoyment of the site. Any such information will not be sold, rented or given to any third party companies.
        <br />Collected information is used by the site to market itself, improve the products it supplies and services offered. If issued a court warrant, the Site will disclose any of the above information to the relevant government or law enforcement entities.
        <br />In no way is this site intended for use by minors. All visitors must be at least 18 or older and in compliance with all local, state or country laws and regulations.
        <br /><br /><h3 className='F16'><strong>Contact</strong></h3>
        <br />If you have any questions regarding our Privacy Policy please feel free to email us at <a href={`mailto:${ContactEmail}`} target='_blank' rel='noopener noreferrer'>{ContactEmail}</a>
    </section>
}
export default Privacy