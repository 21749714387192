import React, { useEffect } from 'react'
import { SetDocumentTitle, SetMetaDescription } from '../../../common/common'

function AboutWebcams() {
    useEffect(() => {
        SetDocumentTitle('Read About Webcams', true)
        SetMetaDescription('Webcam technology, now available in various forms and sizes, captures real-time video and images, transmitting them over the Internet.')
        window.scroll(
            {
                top: 0,
                behavior: 'smooth'
            }
        )
    }, [])

    return <section className='page center-h d-table margin-top-40 w-100 F14'>
        <br /><h1 className='F30 color-light-blue'><strong>What Is a Webcam?</strong></h1>
        <br /><strong>Webcam</strong> technology, now available in various forms and sizes, captures real-time video and images, transmitting them over the Internet. Webcams can be standalone devices connected to a computer via wires or built into computers and cellphones. They facilitate video chat sessions for communication near and far.
        <br />Webcam software allows users to record and live-stream videos on various online platforms. Due to high bandwidth requirements, videos are typically compressed for streaming and uploading.
        <br />Modern webcams serve numerous purposes, including healthcare, video monitoring, e-commerce, video calls, security, content creation, input control, astrophotography, and more. They have become integral to our daily lives, connecting people globally and enhancing safety and communication.
        <br /><br /><h1 className='F16 color-light-blue'><strong>Webcamming History</strong></h1>
        <br />While not necessarily the first, Jenny Ringley, a 1996 American college student and conceptual artist, stands out as one of the earliest and most successful pioneers in this field. She created "JenniCam," a live webcam in her dorm room, capturing moments ranging from everyday activities like brushing teeth and doing laundry to the more intimate stripteases, all broadcasted on the Internet automatically.
        <br />In 1998, a commercial site named AmandaCam followed suit, aiming to replicate JenniCam's concept with multiple cameras capturing mundane and alluring moments around the performer's house. However, Amanda realized the potential for even greater popularity by enabling live chat between performers and viewers. This innovation marked the beginning of webcamming's evolution into a multibillion-dollar industry, with an average of at least 12,500 cam models online and over 240,000 viewers at any given time today.
        <br />
        <br /><br /><h1 className='F16 color-light-blue'><strong>A New Way for Personal Connection and Interaction</strong></h1>
        <br />Modern day webcamming is extremely interactive and playful and there is even a choice between interacting with performers privately, through a private chat, or publicly on a chat to which other viewers can join in. With the use of keyboards, audio, and two-way cameras performers and viewers can have an almost personal interaction with each other, getting to put a face behind someone’s keystrokes. Commonly in public chat rooms viewers can see the performer’s and other viewers’ comments as scrolling text alongside the live video stream. The performers frequently read and respond to the scrolling viewer comments. In a private session, viewers can choose to remain hidden behind their keyboard or reveal themselves by turning on their own camera.
        <br /><br /><h1 className='F16 color-light-blue'><strong>Different Types of Chat</strong></h1>
        <br /><br /><h2 className='F16'><strong>Private Chats</strong></h2>
        <br />A private chat is a way for a viewer to communicate and interact with the model privately. To start a Private Chat, viewers will need to be registered to the specific website’s service, have credit in their account and press a “Start Private” button. Private chats are billed by the minute, but the both the performer and viewer have the power to stop the session at any time. A private chat will stop automatically when the user runs out of tokens.
        <br /><br /><h2 className='F16'><strong>Cam2Cam Show</strong></h2>
        <br />Cam2Cam Shows allow viewers to interact with the model face-to-face. This means that during this session, user can choose to turn on their own webcam and be seen by the model on the other end. To start a Cam2Cam, viewers will need to be registered to the specific website’s service, have credit in their account and press a Cam2Cam Show button. Cam2Cam Shows are billed by the minute, but the both the performer and viewer have the power to stop the session at any time, and it stops automatically when the user runs out of tokens.
        <br />
        <br /><br /><h2 className='F16'><strong>Spy Chats</strong></h2>
        <br />Spy Chat allow users to spy on other viewers private chats, without being an active participant. Spies cannot chat directly with the model and the paying user is not aware of their presence. They can only be passive viewers during that session.
    </section>
}
export default AboutWebcams