import React, { useMemo } from 'react'
import { Alt } from '../../constants'
type Props = {
    type: string
}

export default function Star(props: Props) {
    const star = useMemo(() => {
        try {
            return require(`../../../images/rating/${props.type}_star.png`)
        } catch (error) {
            return ''
        }
    }, [props])

    if (star) {
        return <img src={star} alt={Alt} />
    }
    return <></>
}