import React from 'react'
import DataTable from './datatable/datatable'

function Home() {
    return <section className='home'>
        <DataTable />
        <div className='text-box position-relative margin-top-40 d-table center-h w-80'>
            <div className='text postion-relative d-table margin-top-20 center-h color-black w-100'>
                <h2 className='color-light-blue text-center'><strong>The Webcam World</strong></h2>
                <br /><br />Cam girls are at the forefront of modern online interactions, offering viewers intimate and interactive experiences. These platforms connect performers and audiences, providing a safe environment for interactions. Viewers can choose private or public engagements, with payments made through tokens. Camming websites host numerous models of all different ages and with all the different types of kinks and preferances. As these sites aim to facilitate fun and safe interactions, ensuring the users' and models' welfare, as well as verifying their age, is always a priority. In public chats, tips are the currency, with models receiving a share while the platform collects its cut. Camming has evolved, making it a vital part of our daily digital lives, especially in this post COVID-19 pandemic world.
                <br /><br /><br /><br />
            </div>
        </div>
    </section>
}
export default Home