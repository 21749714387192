import React, { useEffect } from 'react'
import { ContactEmail } from '../../constants'
import { SetDocumentTitle, SetMetaDescription } from '../../../common/common'

const title = 'Terms & Conditions'
function Terms() {
    useEffect(() => {
        SetDocumentTitle(title, true)
        SetMetaDescription('Read our Terms and Conditions on LiveCamOffers.com')
        window.scroll(
            {
                top: 0,
                behavior: 'smooth'
            }
        )
    }, [])
    
    return <section className='page center-h d-table margin-top-40 w-100 F14'>
        <br /><h1 className='F30'><strong>{title}</strong></h1>
        <br /><h3 className='F16'><strong>General Age Requirement</strong></h3>
        <br />You must be at least 18 years old and a consenting adult in order to enter and use the content provided by the 3rd party websites. The site is not intended or designed for minors.
        <br />
        <br /><h3 className='F16'><strong>Third Party Content</strong></h3>
        <br />Visitors to the Site acknowledge that the content is created and provided by 3rd party websites. The Site does not create any sexually explicit content and acts simply as an informative site that describes content that is available on the Internet.
        <br />
        <br /><h3 className='F16'><strong>Ranking</strong></h3>
        <br />Site ranking includes various selection criteria and is determined by the sole discretion of the Site.
        <br />
        <br /><h3 className='F16'><strong>Contact</strong></h3>
        <br />If you should have any questions or queries regarding the Site's Terms and Conditions, please email us at <a href={`mailto:${ContactEmail}`} target='_blank' rel='noopener noreferrer'>{ContactEmail}</a>
        <br />
        <br /><h3 className='F16'><strong>Privacy</strong></h3>
        <br />Please refer to the Privacy Policy page for further details about what information collected from users and how it is used by the Site.
    </section>
}
export default Terms