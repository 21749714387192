import React, { useEffect } from 'react'
import { SetDocumentTitle, SetMetaDescription } from '../../../common/common'

const title = 'COOKIE POLICY'

function CookiePolicy() {
    useEffect(() => {
        SetDocumentTitle(title, true)
        SetMetaDescription('Read our Cookie policy on LiveCamOffers.com')
        window.scroll(
            {
                top: 0,
                behavior: 'smooth'
            }
        )
    }, [])

    return <section className='page center-h d-table margin-top-40 w-100 F14'>
        <br /><h1 className='F30'><strong>{title}</strong></h1>
        <br />Revised on: June 15th, 2020
        <br /><h3 className='F16'><strong>Introduction</strong></h3>
        <br />When you visit or access our Services (such as websites or applications operated by us, or when you interact or engage with our content), we use (and authorize third parties to use) web beacons, cookies, pixel tags, scripts, tags, API and other tracking technologies (“Tracking Technologies”). The Tracking Technologies allow us to automatically collect information about you and your online behavior, as well as your device (for example your computer or mobile device), in order to enhance your navigation on our Services, improve our Services performance and customize your experience on our Services. We also use this information to collect statistics about the usage of our Services, performance analytics, deliver content which is tailored to your interests and administer services to our Users, advertisers, publishers, customers and partners.
        <br />We also allow third parties to collect information about you through Tracking Technologies
        <br /><h3 className='F16'><strong>What are cookies?</strong></h3>
        <br />Cookies are small text files (composed only of letters and numbers) that a web server places on your computer or mobile device when you visit a webpage. When used, the cookie can help make our Services more user-friendly, for example by remembering your language preferences and settings. You can find more information about cookies at <a target='_blank' rel='noopener noreferrer' href='https://www.allaboutcookies.org'>www.allaboutcookies.org</a>.
        <br />Cookies are widely used in order to make websites work in an efficient way. The use of cookies allows you to navigate between pages efficiently. Cookies remember your preferences, and make the interaction between you and the Services smoother and more efficient. Cookies are also used to help ensure that the advertisement you see online are relevant to you and your interests.
        <br />
        <br />
        <br />
        <br /><h3 className='F16'><strong>Storing Tracking Technologies</strong></h3>
        <br />We store Tracking Technologies when you visit or access our Services (for example when you are visit our websites) – these are called "First Party Tracking Technologies". In addition, Tracking Technologies are stored by other third parties (for example our analytics service providers, business partners and advertisers) who run content on our Services- these are called "Third Party Tracking Technologies". Both types of Tracking Technologies may be stored either for the duration of your visit on our Services or for repeat visits.
        <br />
        <br /><br /><h3 className='F16'><strong>What types of Tracking Technologies do we use?</strong></h3>
        <br /><br /><h3 className='F16'><strong>There are five main types of Tracking Technologies:</strong></h3>
        <br /><br /><h3 className='F16'><strong>•	Strictly necessary Tracking Technologies-</strong></h3>
        <br />These Tracking Technologies are essential to enable you to login, navigate around and use the features of our Services, or to provide a service requested by you (such as you username). We do not need to obtain your consent in order to use these Tracking Technologies. These Tracking Technologies can be sued for security and integrity reasons- for example to detect violation of our policies and for support or security features.
        <br /><br /><h3 className='F16'><strong>•	Functionally Tracking Technologies-</strong></h3>
        <br />These Tracking Technologies allow our Services to remember choices you make (such as your language) and provide enhanced and personalized features. For example, these Tracking Technologies are used for authentication (to remember when you are logged-in) and support other features of our Services.
        <br /><br /><h3 className='F16'><strong>•	Performance Tracking Technologies-</strong></h3>
        <br />These Tracking Technologies collect information about your online activity (for example the duration of your visit on our Services) including behavioral data and content engagement metrics. These Tracking Technologies are used for analytics, research and to perform statistics (based on aggregated information).
        <br /><br /><h3 className='F16'><strong>•	Marketing or Advertising Tracking Technologies-</strong></h3>
        <br />These Tracking Technologies are used to deliver tailored offers and advertisements to you, based on your derived interests, as well as to perform email marketing campaign. They can also be sued to limit the number of times you see an advertisement as well as help measure the effectiveness of the advertising campaign . They are usually placed by our advertisers (for example advertising networks) and provide them insights about the people who see and interact with their ads, visit their websites or use their app.
        <br /><br /><h3 className='F16'><strong>•	Social media Tracking Technologies-</strong></h3>
        <br />Our website includes social media features, such as Facebook "Like" or "Share" BUTTONS. These features are either hosted by a third party or hosted directly on our Services. Your interaction with these features are governed by the privacy statement of the company providing these features.
        <br /><br /><br />
        <table className='center-h' style={{ 'border': '1px solid black' }}>
            <style>{`
              table th, table td{
               border:1px double black;
               padding: 10px
              }
            `}</style>
            <thead>
                <tr>
                    <th>Tracking</th>
                    <th>Technologies Type</th>
                    <th>Purpose</th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td>
                        Analytics Cookies
                    </td>
                    <td>
                        First party Tracking Technology
                    </td>
                    <td>
                        Performance Tracking Technologies These Tracking Technologies are used to collect information regarding how you interact with the content on our Services, attribution purposes (for example, the referral URL), etc. We use the information to compile reports, calculate the revenues due to us and, help us improve the Services and to offer personalized products and content.
                    </td>
                </tr>
                <tr>
                    <td>
                        Google Analytic
                    </td>
                    <td>
                        First party Tracking Technology
                        </td>
                    <td>
                        Performance Tracking Technologies This cookie is used to collect information about how visitors use our Services. We use the information to compile reports and to help us improve the Services. The cookies collect the number of visitors to the Services, where visitors have come to the Services from and the pages they visited, and other data. Google Privacy Policy
                        </td>
                </tr>
                <tr>
                    <td>
                        Other cookies
                    </td>
                    <td>
                        First and Third party Tracking Technology
                    </td>
                    <td>
                        Strictly necessary Tracking Technologies - these unlisted cookies might be in use on internal sections of the Services, in order to customize and simplify the user experience on the site by remembering choices you made and your log in credentials.
                    </td>
                </tr>
            </tbody>
        </table>
        <br />

        <br />
        <br /><h3 className='F16'><strong>How to manage your cookie settings</strong></h3>
        <br />Please note that we do not recognize or respond to automated browser signals regarding Tracking Technologies, including "Do Not Track" requests. However, there are various ways in which you can manage and control your cookie settings. Please remember that, by deleting or blocking cookies, some of the features of the Services may not work properly or effectively.
        <br />•	Turning off cookies via your web browser
        <br />•	Most web browsers will provide you with some general information about cookies, enable you to see what cookies are stored on your device, allow you to delete them all or on an individual basis, and enable you to block or allow cookies for all websites or individually selected websites. You can also normally turn off third party cookies separately. Please note that the settings offered by a browser or device often only apply to that particular browser or device.
        <br />•	Information about cookies is usually found in the "Help" section of the web browser.
        <br /><br />Below are some commonly used web browsers:
        <br />•	Chrome
        <br />•	Chrome for mobile
        <br />•	Internet Explorer and Microsoft Edge
        <br />•	Mozilla Firefox
        <br />•	Safari
        <br />
        <br /><h3 className='F16'><strong>•	Turning off third party cookie</strong></h3>
        <br />You can turn off certain third party at targeting/advertising cookies by visiting the following link: networkadvertising.org/choices
    </section>
}
export default CookiePolicy