import React, { lazy, Suspense } from 'react'
import { isMobile } from 'react-device-detect'

const DesktopDataTable = lazy(() => import('./desktop/datatable'))
const MobileDataTable = lazy(() => import('./mobile/datatable'))

function DataTable() {
    return <>
        <Suspense fallback={<div className='block-screen bg-semi-black'><div className='loader'></div></div>}>
            {!isMobile && <DesktopDataTable />}
            {isMobile && <MobileDataTable />}
        </Suspense>
    </>
}
export default DataTable