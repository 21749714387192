import React, { useEffect, Suspense, useState } from 'react'
import { SetDocumentTitle, SetMetaDescription } from '../../../common/common'
import { RedirectQueryKey } from '../../constants'
import { useLocation, useParams } from 'react-router-dom'
import GoToSite from '../../main/review/gotosite/gotosite'
import dataTable from '../../main/home/datatable.json'

const title = 'Who Are We and What We Do?'

function AboutUs() {
    const { site } = useParams()
    const [sitelink, setSiteLink] = useState('')
    const [goToExternal, setGoToExternal] = useState(false)

    useEffect(() => {
        SetDocumentTitle(title, true)
        SetMetaDescription('Our goal is simple: to offer a comprehensive, impartial, and detailed assessment of the leading live cam websites.')
        window.scroll(
            {
                top: 0,
                behavior: 'smooth'
            }
        )
    }, [])

    useEffect(() => {
        if (site) {
            const data = dataTable.filter(data => data.site.toLocaleLowerCase() === site.toLocaleLowerCase())[0]
            if (data) {
                setSiteLink(data.sitelink)
            }
        }
    }, [site])

    const query = useQuery()
    useEffect(() => {
        if (query) {
            setGoToExternal(Number(query.get(RedirectQueryKey)) > 0)
        }
    }, [query])

    function useQuery() {
        return new URLSearchParams(useLocation().search)
    }

    return <section className='page center-h d-table margin-top-40 w-100 F14'>
        <h1 className='F30 color-light-blue'><strong>{title}</strong></h1>
        <br /><br />Our goal is simple: to offer a comprehensive, impartial, and detailed assessment of the leading live cam websites. Our reviewers are seasoned video cam enthusiasts with a deep understanding of the evolution of camming platforms, as well as expertise in user-friendly web design and interfaces.
        <br /><br /><strong className='color-light-blue'>What's the Review Process Like?</strong>
        <br /><br />Our thorough process encompasses crucial details, including pricing, model availability, and quality, along with often-overlooked factors that new webcam users might not consider, such as site layout, camera quality, and unique features. Our team members have extensively explored each reviewed site at various times of the day, engaging with its features as free or unregistered users, paying members, and premium subscribers.
        <br /><br /><strong className='color-light-blue'>Why Should You Trust Us?</strong>
        <br /><br />We've done the leg work! Our reviews are unsolicited and are unpaid by external sites.
        <br /><br /><strong className='color-light-blue'>How Do We Make Money?</strong>
        <br /><br />We make our money through referral links.
        <br /><br /><strong className='color-light-blue'>How Do We Decide Which Sites to Review?</strong>
        <br /><br />Our team members access the websites, engage in conversations and interactions with the models, and thoroughly evaluate the platform's diverse features. Additionally, we consider factors such as popularity, model selection, average per-minute pricing, feedback from users, the site's longevity, its security measures, and many other aspects during our assessment.
        {
            goToExternal && <Suspense fallback={<>Loading...</>}>
                <GoToSite site={site} sitelink={sitelink} />
            </Suspense>
        }
    </section>
}
export default AboutUs