import React, { useEffect } from 'react'
import { ContactEmail } from '../../constants'
import { SetDocumentTitle, SetMetaDescription } from '../../../common/common'

const title = 'Contact Us'

function ContactUs() {
    useEffect(() => {
        SetDocumentTitle(title, true)
        SetMetaDescription('For any questions, remarks or you just want to share your thoughts')
        window.scroll(
            {
                top: 0,
                behavior: 'smooth'
            }
        )
    }, [])

    return <section className='page center-h d-table margin-top-40 w-100 F14'>
        <br /><h1 className='F30'><strong>{title}</strong></h1>
        <br /><br />For any questions, remarks or you just want to share your thoughts
        <br /><br />Feel free to contact us by emailing to <a href={`mailto:${ContactEmail}`} target='_blank' rel='noopener noreferrer'>{ContactEmail}</a>
    </section>
}
export default ContactUs