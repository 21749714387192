export const Sitename = 'LiveCamOffers.com'
export const Year = (new Date()).getFullYear()
export const DocumentTitle = `Best Live Cam Sites of ${Year} | LiveCamOffers.com`
export const Alt = DocumentTitle
export const MetaDescription = 'The Most Reliable Source Serves you with only the Best Webcam Sites Available Online. We tailored the best offers just for you.'
export const ContactEmail = 'info@livecamoffers.com'
export const NoImage = require('../images/no-image.png')
export const RedirectQueryKey = 'go'
export const RedirectQuery = `?${RedirectQueryKey}=1`
export const QueryReview = 'review'
export const QueryAbout = 'about-us'
export const MsInSeconds = 1000
export const PromocodeKey = 'promocodeKey'
export const PromocodeQuery = 'promocode'
export const BottomBannerSrc = `/review/ImLive?${RedirectQueryKey}=2`

export type FeatureType = {
    bold: boolean,
    exists: boolean,
    text: string
}
export type SiteProps = {
    site: string,
    sitelink: string
}
