import React, { useMemo } from 'react'
import './header.css'
import Offer from '../../offer/offer'
import Score from '../../score/score'
import { NoImage } from '../../../constants'
import { isMobile } from 'react-device-detect';

type Props = {
    site: string,
    rank: number,
    sitelink: string,
    offertext: string,
    score: number
}
function ReviewHeader(props: Props) {
    const logo = useMemo(() => {
        try {
            return require(`../../../../images/main/sites-logo/${props.site.toLowerCase()}.png`)
        } catch (error) {
            return NoImage
        }
    }, [props])

    return <section className={`review-header d-table position-relative w-inherit border-box bg-white ${!isMobile ? 'desktop-content-width' : 'mobile-content-width'}`}>
        <div className='img-cont d-table position-relative float-left'>
            <img className='d-block' src={logo} alt={props.site} />
            <h1 className='F16 margin-top-10 color-grey sitename'>{props.site} Review</h1>
        </div>
        <div className='position-relative d-table float-left background second-row'>
            <div className='position-relative offer-cont float-left d-table'>
                <Offer rank={props.rank} link={props.sitelink} text={props.offertext} site={props.site} />
            </div>
            <div className='position-relative score-wrapper float-left d-table'>
                <Score score={props.score} />
            </div>
        </div>
    </section>
}
export default ReviewHeader