import React from 'react'
import {
  BrowserRouter as Router
} from "react-router-dom"

import Header from './components/header/header'
import Footer from './components/footer/footer'
import { isMobile } from 'react-device-detect'
import Main from './components/main/main'
import StartupPopup from './components/popups/StartupPopup'

function App() {
  return (
    <div className={'app d-table w-100 position-relative ' + (!isMobile ? 'isDesktop' : 'isMobile')}>
      <Router>
        <Header />
        <Main />
        <Footer />
        <StartupPopup />
      </Router>
    </div>
  );
}


export default App;
