import React, { useRef, useEffect, useState } from 'react'
import './countdown.css'
import { v4 as uuidv4 } from 'uuid';
import { MsInSeconds } from '../../../../constants'

type Props = {
    seconds: number
}

function CountDown(props: Props) {
    const coundownRef = useRef<HTMLDivElement>(null)
    const marginRef = useRef(0)
    const currentCountRef = useRef(0)
    const [counts, setCounts] = useState(new Array<JSX.Element>())

    useEffect(() => {
        const timer = window.setInterval(() => {
            if (coundownRef.current) {
                const first = coundownRef.current.children[0] as HTMLDivElement;
                if (first) {
                    marginRef.current += first.clientHeight
                    first.style.marginTop = (-1 * marginRef.current) + 'px'
                    currentCountRef.current++;
                }
            }

            if (currentCountRef.current === props.seconds) {
                clearInterval(timer)
            }
        }, MsInSeconds)

        let countsArr = new Array<JSX.Element>(Math.ceil(props.seconds) + 1)
        for (let i = 0; i < countsArr.length; i++) {
            countsArr[i] = <div key={uuidv4()} className='count'>{countsArr.length - i - 1}</div>
        }
        setCounts(countsArr)
    }, [props.seconds])

    return <div ref={coundownRef} className='countdown d-block position-relative margin-top-20 center-h'>
        {counts}
    </div>
}
export default CountDown