import React, { useEffect, useState } from 'react'
import './startupPopup.css'
import Banner from '../../images/popups/promotion-popup.jpg'
import { useLocation } from 'react-router-dom'
import { getCookie, setCookie } from '../../common/common'


function StartupPopup() {

    const [showPopup, setShowPopup] = useState(false);
    const currentPath = useLocation().pathname;

    const showedHomepage = getCookie("popup-showed-homepage");
    const showedInner = getCookie("popup-showed-inner");

    useEffect(() => {
        
        if (currentPath == "/" && !showedHomepage && !showPopup) {
            setCookie('popup-showed-homepage', 'true', 1);
            setTimeout(() => {
                setShowPopup(true);
            }, 3000)
        }

        if (currentPath != "/" && !showedInner && !showPopup) {
            setCookie('popup-showed-inner', 'true', 1);
            setTimeout(() => {
                setShowPopup(true);
            }, 3000)
        }
        
    }, [currentPath])


    const closePopup = (e: React.MouseEvent<HTMLElement>) => {
        e.stopPropagation();
        setShowPopup(false);
    }

    return (
        <>
            {showPopup && (
                <div id="start-popup" className="close-pop-btn" onClick={closePopup}>
                    <div className="pop-content">
                        <div className="close-pop close-pop-btn" onClick={closePopup}></div>
                        <a target="_blank" href="/goto/iml-main-pop">
                            <img src={Banner} alt="" />
                        </a>
                    </div>
                </div>
            )}
        </>
    )
}
export default StartupPopup