import React, { useState } from 'react'
import LargImg from '../../images/banners/footer-bnr_1920x100.png'
import MinimizedImg from '../../images/banners/footer-bnr_1920x30.png'
import MobileImg from '../../images/banners/footer-bnr_368x70.png'
import './bottomBanner.css'
import { BottomBannerSrc } from '../constants'

const BottomBanner = () => {

    const [minimized, setMinimized] = useState(false);
    
    return (
        <div className={`bottomBnr ${minimized ? "minimized" : ""}`}>
            <div onClick={() => setMinimized(!minimized)} className={`minimizeBnr`}><span>-</span></div>
            <a href={BottomBannerSrc} target='_blank'>
                <picture>
                    <source media="(max-width: 800px)" srcSet={MobileImg} />
                    <img src={`${minimized ? MinimizedImg : LargImg}`} alt="imlive promotion" />
                </picture>
            </a>
        </div>
    )
}

export default BottomBanner;