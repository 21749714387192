import React from 'react'
import { Link } from 'react-router-dom'
import Legal from '../legal/legal'
import './footer.css'
import BottomBanner from '../banners/BottomBanner'

function Footer() {

    return (
        <footer className='clear-both position-absolute w-100'>
            <Legal />
            <section className='footer-links margin-top-10'>
                <nav className='center-h d-table'>
                    <Link to='/privacy'>PRIVACY</Link>
                    <Link to='/cookie-policy'>COOKIE POLICY</Link>
                    <Link to='/terms-and-conditions'>TERMS AND CONDITIONS</Link>
                    <Link to='/contact-us'>CONTACT US</Link>
                </nav>
            </section>
            <BottomBanner />
        </footer>
    )
}
export default Footer