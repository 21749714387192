import React from 'react'
import './legal.css'

function Legal() {
    return <section className='clear-both w-100 text-center F14 legal'>
            <strong>No nudity is allowed in the site.</strong>
            <br />This site complies with 18 U.S.C. § 2257, and its regulations.
            <br />The owners and operators of this website are not the primary producer (as that term is defined in 28 C.F.R. § 75.1(c)(2)) of any of the visual content contained in this website.
        </section>
}
export default Legal