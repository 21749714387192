import React, { useRef, useEffect, useMemo } from 'react'
import './gotosite.css'
import { NoImage, MsInSeconds } from '../../../constants'
import CountDown from './countdown/countdown'
import { BuildExternalURL } from '../../../../common/common'

type Props = {
    site: string,
    sitelink: string
}
const countDown = 1.5//s
const hideClass = 'd-none-important'

function GoToSite(props: Props) {
    const logo = useMemo(() => {
        try {
            return require(`../../../../images/main/sites-logo/${props.site.toLowerCase()}.png`)
        } catch (error) {
            return NoImage
        }
    }, [props])

    const timerRef = useRef(-1)
    const sectionRef = useRef<HTMLDivElement>(null)

    useEffect(() => {
        if (sectionRef.current) {
            sectionRef.current.classList.remove(hideClass);

            timerRef.current = window.setTimeout(() => {
                const encodedLink = BuildExternalURL(props.sitelink)
                window.location.href = encodedLink;
            }, countDown * MsInSeconds)
        }
    }, [props.sitelink])


    function handleClick(): void {
        if (timerRef.current) {
            clearInterval(timerRef.current)
        }
        if (sectionRef.current) {
            sectionRef.current.classList.add(hideClass);
        }
    }
    return <section ref={sectionRef} className={`block-screen go-to-site ${hideClass}`}>
        <div className='center-content content bg-white'>
            <div className='color-black position-absolute close' onClick={() => handleClick()}>
                &times;
            </div>
            <h1 className='F30 text-center color-light-blue'>YOU'll BE REDIRECTED TO</h1>
            <img className='d-block center-h margin-top-10' src={logo} alt={props.site} />
            <h1 className='F30 text-center color-light-blue margin-top-10'>in</h1>
            <CountDown seconds={countDown} />
            <a href={props.sitelink} className='margin-top-10 text-center text-decoration-u d-table center-h color-light-blue F20'><strong>Take Me There!</strong></a>
        </div>
    </section>
}
export default GoToSite