import { DocumentTitle, MetaDescription, Sitename, PromocodeKey, PromocodeQuery } from "../components/constants"

export function SetMetaDescription(content?: string): void {
    if (!content) {
        content = MetaDescription
    }
    document.querySelector('meta[name="description"]')?.setAttribute('content', content)
}
export function SetDocumentTitle(title?: string, addMe: boolean = false): void {
    if (!title) {
        title = DocumentTitle
    }

    if (addMe) {
        title = `${title} | ${Sitename}`
    }
    document.title = title
}
export function SetDefaultMetaDescription(): void {
    SetMetaDescription(MetaDescription)
}
export function SetDefaultDocumentTitle(): void {
    SetDocumentTitle(DocumentTitle)
}

export function BuildExternalURL(url: string): string {
    if (!url) {
        return ''
    }

    const promoCodeSuffix = localStorage.getItem(PromocodeKey)
    if (promoCodeSuffix !== null) {
        url = GetUrlWithPromocode(url, promoCodeSuffix);
    }

    return encodeURI(url)
}

export function GetUrlWithPromocode(url: string, promoCodeSuffix: string): string {
    if (!url) {
        return ''
    }

    if (!promoCodeSuffix) {
        return url;
    }

    const _url = new URL(url);
    if (!_url.search) {
        url += '?'
    }
    if (_url.search.indexOf(PromocodeQuery) < 0) {
        url += `${PromocodeQuery}=${promoCodeSuffix.slice(1)}`
    } else {
        url += promoCodeSuffix
    }
    return url
}


export function setCookie(name: string, value: string , days: number) {
    let expires = "";
    if (days) {
        const date = new Date();
        date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
        expires = "; expires=" + date.toUTCString();
    }
    document.cookie = name + "=" + encodeURIComponent(value) + expires + "; path=/";
}

export function getCookie(name: string) {
    const nameEQ = name + "=";
    const ca = document.cookie.split(';');
    for (let i = 0; i < ca.length; i++) {
        let c = ca[i];
        while (c.charAt(0) === ' ') c = c.substring(1, c.length);
        if (c.indexOf(nameEQ) === 0) return decodeURIComponent(c.substring(nameEQ.length, c.length));
    }
    return null;
}