import React, { useEffect, useState } from 'react'
import './score.css'
import Star from './star';
import { v4 as uuidv4 } from 'uuid';
type Props = {
    score: number
}
const maxScore = 10
const starsAmount = 5
const star = {
    full: 'full',
    half: 'half',
    empty: 'empty'
}
function Score(props: Props) {
    const [stars, setStars] = useState(new Array<JSX.Element>(starsAmount))
    useEffect(() => {
        setStars(getStars())

        function getStars(): JSX.Element[] {
            const ceiledScore = Math.floor(props.score)
            const fullStars = Math.floor(ceiledScore / 2)
            const halfStars = (ceiledScore % 2 === 0 ? 0 : 1)
            const emptyStars = starsAmount - halfStars - fullStars
            let stars = new Array<JSX.Element>(starsAmount)
            let i: number
            for (i = 0; i < fullStars; i++) {
                stars[i] = <Star key={uuidv4()} type={star.full} />
            }
            for (i = 0; i < halfStars; i++) {
                stars[i + fullStars] = <Star key={uuidv4()} type={star.half} />
            }
            for (i = 0; i < emptyStars; i++) {
                stars[i + fullStars + halfStars] = <Star key={uuidv4()} type={star.empty} />
            }
            return stars
        }
    }, [props.score])

    return <div className='score-cont d-table position-realtive center-h color-dark-grey font-weight-300'>
        <div className='score'>{props.score.toFixed(1)}/<span className='max-score'>{maxScore}</span></div>
        <div className='rating'>
            {stars}
        </div>
    </div>
}
export default Score