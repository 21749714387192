import React, { useRef, useEffect } from 'react'
import { Switch, Route, useLocation } from 'react-router-dom'
import Home from './home/home'
import Privacy from '../footer/privacy/privacy'
import CookiePolicy from '../footer/coockiepolicy/coockiepolicy'
import Terms from '../footer/terms/terms'
import { TransitionGroup, CSSTransition } from 'react-transition-group'
import Review from './review/review'
import AboutUs from '../header/aboutus/aboutus'
import AboutWebcams from '../header/aboutwebcams/aboutwebcams'
import { isMobile } from 'react-device-detect'
import ContactUs from '../footer/contactus/contactus'
import { SetMetaDescription, SetDocumentTitle } from '../../common/common'
import { PromocodeKey } from '../constants'

function Main() {
    const nodeRef = useRef(null)//prevents Warning: findDOMNode is deprecated in StrictMode
    const location = useLocation()

    function onLeave(): void {
        SetDocumentTitle()
        SetMetaDescription();
    }


    useEffect(() => {
        if (window.location.pathname !== '/') {
            return
        }

        const query = new URLSearchParams(window.location.search)
        handleTrackingТemplate();
        ///when user arrive via google links with params
        function handleTrackingТemplate(): void {
            let promo = ''
            query.forEach((v, k) => {
                promo += `_${decodeURIComponent(v)}`;
            });

            localStorage.setItem(PromocodeKey, promo)
            window.history.pushState({}, document.title, '/')
        }
    }, [])

    return <>
        <TransitionGroup className={`position-relative d-table center-h border-box ${isMobile ? 'mobile-content-width' : 'desktop-content-width'}`}>
            <CSSTransition nodeRef={nodeRef} key={location.key} classNames='fade'
                timeout={1000}>
                <section className='switch'>
                    <Switch location={location}>
                        <Route exact path='/'>
                            <Home />
                        </Route>
                        <Route path='/privacy'>
                            <Privacy />
                        </Route>
                        <Route path='/cookie-policy'>
                            <CookiePolicy />
                        </Route>
                        <Route path='/terms-and-conditions'>
                            <Terms />
                        </Route>
                        <Route path='/review/:site' onLeave={onLeave()}>
                            <Review />
                        </Route>
                        <Route path='/about-us/:site?'>
                            <AboutUs />
                        </Route>
                        <Route path='/about-webcams'>
                            <AboutWebcams />
                        </Route>
                        <Route path='/contact-us'>
                            <ContactUs />
                        </Route>
                        <Route path='*'>
                            <Home />
                        </Route>
                    </Switch>
                </section>
            </CSSTransition>
        </TransitionGroup>
        <style>
            {
                `
                .fade-enter {
                    opacity: 0.01;
                  }
                  
                  .fade-enter.fade-enter-active {
                    opacity: 1;
                    transition: opacity 300ms ease-in;
                  }
                  
                  .fade-exit {
                    opacity: 1;
                  }
                  
                  .fade-exit.fade-exit-active {
                    opacity: 0.01;
                    transition: opacity 300ms ease-in;
                    position:absolute;
                  }
                  
                  .switch {
                    position: relative;
                    width: 100%;
                    top: 0;
                    left: 0;
                  }
                `
            }
        </style>
    </>
}
export default Main