import React, { lazy, Suspense } from 'react'
import { isMobile } from 'react-device-detect'
const DesktopOffer = lazy(() => import('./desktop/offer'))
const MobileOffer = lazy(() => import('./mobile/offer'))
type Props = {
    rank: number,
    text: string,
    link: string,
    site: string,
    query?: string
}

function Offer(props: Props) {
    const query = props.query || ''
    return <>
        <Suspense fallback={<></>}>
            {!isMobile && <DesktopOffer rank={props.rank} query={query} text={props.text} site={props.site} link={props.link} />}
            {isMobile && <MobileOffer query={query} text={props.text} site={props.site} link={props.link} />}
        </Suspense>
    </>
}

export default Offer