import { Sitename } from "../../../constants"

export type ContentProps = {
    title: string,
    text: string[],
    list: string[]
}
export const SectionNavId = 'section'
export function GetDocumentTitle(site: string): string {
    return `${site} Review | ${Sitename}`
}
export function GetMetaDescription(site: string): string {
    return `Read about ${site} and get all the information you need in order to get the most valuable and enjoying live cam experience.`
}